import React from 'react';
import './Availability.css'
function Availability() {
  return (
    <div>
       <section className="sec">
<div><h2 className='maineasy'>Easy Availability and Accessibility</h2>
    <div className="easy">
    <div className="Avilcard1">
        <div className='AvilcardStyle'>
            <div className='stickerStyles'>
           <div className="sticker">
            <svg aria-hidden="true" className="e-font-icon-svg e-far-user stickerLogo" viewBox="0 0 448 512" height="40px" fill="#C71585" xmlns="http://www.w3.org/2000/svg">
            <path d="M313.6 304c-28.7 0-42.5 16-89.6 16-47.1 0-60.8-16-89.6-16C60.2 304 0 364.2 0 438.4V464c0 26.5 21.5 48 48 48h352c26.5 0 48-21.5 48-48v-25.6c0-74.2-60.2-134.4-134.4-134.4zM400 464H48v-25.6c0-47.6 38.8-86.4 86.4-86.4 14.6 0 38.3 16 89.6 16 51.7 0 74.9-16 89.6-16 47.6 0 86.4 38.8 86.4 86.4V464zM224 288c79.5 0 144-64.5 144-144S303.5 0 224 0 80 64.5 80 144s64.5 144 144 144zm0-240c52.9 0 96 43.1 96 96s-43.1 96-96 96-96-43.1-96-96 43.1-96 96-96z"></path>
             </svg>
           </div>
           </div>
    <h3 className="custom">Customer Support</h3>
    <p className='easypera'>Your Support Team merges technical prowess with sincere care, fostering trust through expertise and empathy. They build lasting relationships, transcending mere service to create meaningful connections.</p>
    </div>
</div>
        <div className="availcard2">
            <div className="cd1">
            <div className="c1">
                <div className="icon1">
                    <svg aria-hidden="true" className="e-font-icon-svg e-fab-superpowers stickerLogo" fill="#C71585" height="40px" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M448 32c-83.3 11-166.8 22-250 33-92 12.5-163.3 86.7-169 180-3.3 55.5 18 109.5 57.8 148.2L0 480c83.3-11 166.5-22 249.8-33 91.8-12.5 163.3-86.8 168.7-179.8 3.5-55.5-18-109.5-57.7-148.2L448 32zm-79.7 232.3c-4.2 79.5-74 139.2-152.8 134.5-79.5-4.7-140.7-71-136.3-151 4.5-79.2 74.3-139.3 153-134.5 79.3 4.7 140.5 71 136.1 151z"></path></svg>
                </div>
                   <div className="txt1"><h3 className="Swift">Swift Deployment</h3>
                       <p className="easypera">Choose any deployment method—Teams, Outlook, or SharePoint—that suits your preferences.</p>
                    </div>
            </div>
               
                <div className="c2">
                <div className="icon2"><svg aria-hidden="true" className="e-font-icon-svg e-far-snowflake stickerLogo" fill='#C71585' height="40px" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M440.1 355.2l-39.2-23 34.1-9.3c8.4-2.3 13.4-11.1 11.1-19.6l-4.1-15.5c-2.2-8.5-10.9-13.6-19.3-11.3L343 298.2 271.2 256l71.9-42.2 79.7 21.7c8.4 2.3 17-2.8 19.3-11.3l4.1-15.5c2.2-8.5-2.7-17.3-11.1-19.6l-34.1-9.3 39.2-23c7.5-4.4 10.1-14.2 5.8-21.9l-7.9-13.9c-4.3-7.7-14-10.3-21.5-5.9l-39.2 23 9.1-34.7c2.2-8.5-2.7-17.3-11.1-19.6l-15.2-4.1c-8.4-2.3-17 2.8-19.3 11.3l-21.3 81-71.9 42.2v-84.5L306 70.4c6.1-6.2 6.1-16.4 0-22.6l-11.1-11.3c-6.1-6.2-16.1-6.2-22.2 0l-24.9 25.4V16c0-8.8-7-16-15.7-16h-15.7c-8.7 0-15.7 7.2-15.7 16v46.1l-24.9-25.4c-6.1-6.2-16.1-6.2-22.2 0L142.1 48c-6.1 6.2-6.1 16.4 0 22.6l58.3 59.3v84.5l-71.9-42.2-21.3-81c-2.2-8.5-10.9-13.6-19.3-11.3L72.7 84c-8.4 2.3-13.4 11.1-11.1 19.6l9.1 34.7-39.2-23c-7.5-4.4-17.1-1.8-21.5 5.9l-7.9 13.9c-4.3 7.7-1.8 17.4 5.8 21.9l39.2 23-34.1 9.1c-8.4 2.3-13.4 11.1-11.1 19.6L6 224.2c2.2 8.5 10.9 13.6 19.3 11.3l79.7-21.7 71.9 42.2-71.9 42.2-79.7-21.7c-8.4-2.3-17 2.8-19.3 11.3l-4.1 15.5c-2.2 8.5 2.7 17.3 11.1 19.6l34.1 9.3-39.2 23c-7.5 4.4-10.1 14.2-5.8 21.9L10 391c4.3 7.7 14 10.3 21.5 5.9l39.2-23-9.1 34.7c-2.2 8.5 2.7 17.3 11.1 19.6l15.2 4.1c8.4 2.3 17-2.8 19.3-11.3l21.3-81 71.9-42.2v84.5l-58.3 59.3c-6.1 6.2-6.1 16.4 0 22.6l11.1 11.3c6.1 6.2 16.1 6.2 22.2 0l24.9-25.4V496c0 8.8 7 16 15.7 16h15.7c8.7 0 15.7-7.2 15.7-16v-46.1l24.9 25.4c6.1 6.2 16.1 6.2 22.2 0l11.1-11.3c6.1-6.2 6.1-16.4 0-22.6l-58.3-59.3v-84.5l71.9 42.2 21.3 81c2.2 8.5 10.9 13.6 19.3 11.3L375 428c8.4-2.3 13.4-11.1 11.1-19.6l-9.1-34.7 39.2 23c7.5 4.4 17.1 1.8 21.5-5.9l7.9-13.9c4.6-7.5 2.1-17.3-5.5-21.7z"></path></svg></div>
                    <div className="txt2">
                    <h3 className="Swift">Modern UI/UX</h3>
                    <p className="easypera">Every app is crafted using the latest Fluent UI, ensuring seamless functionality across all devices.</p>
                    </div>
                </div>
            </div>
            <div className="cd2">
                <div className="c3">
                  <div className="icon3"><svg aria-hidden="true" className="e-font-icon-svg e-fab-microsoft stickerLogo" fill="#C71585" height="40px" viewBox="0 0 448 512" xmlns="http://www.w3.org/2000/svg"><path d="M0 32h214.6v214.6H0V32zm233.4 0H448v214.6H233.4V32zM0 265.4h214.6V480H0V265.4zm233.4 0H448V480H233.4V265.4z"></path></svg></div>
                    <div className="txt3"><h3 className="Swift">Microsoft Appstore</h3>
                    <p className="easypera">Our innovative applications are available on the Microsoft Appstore.</p>
                    </div>
                </div>
                <div className="c4">
                    <div className="icon4"><svg aria-hidden="true" className="e-font-icon-svg e-fas-user-friends stickerLogo"fill="#C71585" height="40px" viewBox="0 0 640 512" xmlns="http://www.w3.org/2000/svg"><path d="M192 256c61.9 0 112-50.1 112-112S253.9 32 192 32 80 82.1 80 144s50.1 112 112 112zm76.8 32h-8.3c-20.8 10-43.9 16-68.5 16s-47.6-6-68.5-16h-8.3C51.6 288 0 339.6 0 403.2V432c0 26.5 21.5 48 48 48h288c26.5 0 48-21.5 48-48v-28.8c0-63.6-51.6-115.2-115.2-115.2zM480 256c53 0 96-43 96-96s-43-96-96-96-96 43-96 96 43 96 96 96zm48 32h-3.8c-13.9 4.8-28.6 8-44.2 8s-30.3-3.2-44.2-8H432c-20.4 0-39.2 5.9-55.7 15.4 24.4 26.3 39.7 61.2 39.7 99.8v38.4c0 2.2-.5 4.3-.6 6.4H592c26.5 0 48-21.5 48-48 0-61.9-50.1-112-112-112z"></path></svg></div>
                    <div className="txt4"><h3 className="Swift">Free Updates </h3>
                    <p className="easypera">Our valued subscribers enjoy unlimited Support, enhancements, features and updates.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
</section>
    </div>
  )
}
export default Availability;